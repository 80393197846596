import React, {useState} from 'react';
import './App.css';
import VideoPlayer from './VideoPlayer'
import {
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function App() {
  let query = useQuery();
  const [video, setVideo] = useState(query.get("id"));

  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: `https://files.johnsonyuen.com/${video}/hls.m3u8`,
      type: 'application/vnd.apple.mpegurl'
    }]
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

    
  return (
    <div className="App">
        <h1>Video Player</h1>
        Video:
            <input
                name='name'
                type='text'
                value={video ? video : ''}
                onChange={e => setVideo(e.target.value)}
            />
            <br />
      <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
    </div>
  );
}

export default App;
